
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {signUps} from '../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import {SignUp} from '@/externals/MaxCI-SignUp-v1';
import xlsx from 'xlsx';

@Component({
  name: 'partTimeList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private searchData = {name: ''};
  private dialogDel = false;
  private operateId = '';
  private total = 0;
  private list: Array<SignUp> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };

  async created() {
    this.updateList();
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //导出
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push(['姓名', '性别', '手机号', '学校']);
      const list = await signUps.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$eq(this.searchData.name),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage.$sort(sort =>
                sort(f => f('metadata')('updationTimestamp'), '降序'),
              ),
            ),
          ),
      );
      list[0].table.forEach(item => {
        reportList.push([
          item.spec.name,
          item.spec.gender,
          item.spec.phone,
          item.spec.school,
        ]);
      });
      const filename = moment().format('YYYY-MM-DD') + '兼职报名表.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await signUps.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$eq(this.searchData.name),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
